<template>
  <div>

    <a-layout style="height:95vh">
      <a-layout-sider
        v-model="collapsed"
        :trigger="null" collapsible
         style="min-width:250px;width:250px;padding:10px;background:#fff"
        >
        <h1>Subscriber</h1>
        <a-menu
            @click=""
          >
          <a-menu-item>
            View All
          </a-menu-item>
        </a-menu>

        <a-form layout="vertical">
            <a-form-item label="Usecases" >
              <a-checkbox-group v-model="usecaseFilter.selected" :options="usecaseFilter.options"/>
            </a-form-item>
          </a-form>

      </a-layout-sider>

      <a-layout>
        <a-layout-header style="background: #fff; padding: 10px">
          <a-breadcrumb>
            <a-breadcrumb-item>
              <router-link :to="{path: '/' }"><a-icon type="home" /></router-link>
            </a-breadcrumb-item>
            <a-breadcrumb-item href="">
              <router-link :to="{path: '/n/' + $route.params.namelist_name}">
                  <span>{{ $route.params.namelist_name }}</span>
              </router-link>
            </a-breadcrumb-item>
            <a-breadcrumb-item>
              Subscribers
            </a-breadcrumb-item>
          </a-breadcrumb>
        </a-layout-header>
        <a-layout-content
          :style="{  padding: '10px', background: '#fff', minHeight: '280px' }"
        >
        <a-row>
          <a-col span="3" style="margin-bottom:10px">
            <a-input allow-clear ref="tableFilterInput" v-model="subscriberTable.filter" placeholder="filter">
              <a-icon slot="prefix" type="search" />
            </a-input>
          </a-col>
        </a-row>
        <a-table 
            :pagination="pagination"
            @change="handleTableChange"
            size="small"
            rowKey="id"
            :columns="subscriberTable.columns"
            :data-source="filteredSubs" :scroll="{ x: 1500, y: 800 }">


          <span slot="reference" slot-scope="reference, data">
            <div class="strong">
              <a @click="subscriberRowSelected(data)" href="javascript:void(0);">{{ reference }}</a>
            </div>
          </span>

          <span slot="name" slot-scope="name, data">
            <div class="strong">
              <a @click="subscriberRowSelected(data)" href="javascript:void(0);">{{ name }}</a>
            </div>
          </span>
          <span slot="employer" slot-scope="employer, data">
            <div>
              <div>{{ data.employer_name }}</div>
              <div v-for="employment_title in data.employment_titles">
              {{ employment_title }}
              </div>
              <div>{{ data.employment_title }}</div>
              <div>{{ data.employment_status }}</div>
            </div>
          </span>
          <span slot="linkedin" slot-scope="profile, data">
            <section>
              <div v-if="profile && profile.name">
                <div class="strong">
                  <a target="_blank" :href="profile.link">{{ profile.name }}</a>
                  <a-icon theme="filled" style="padding-left:10px;font-size:15px;color:#0b6cff" type="linkedin" />
                </div>
                <div class="cell_text">{{ profile.headline }}</div>
                <div class="cell_text">{{ profile.locationName }}</div>
              </div>
            </section>
          </span>
          <span slot="linkedin_industry" slot-scope="profile, data">
            <section>
              <div v-if="profile && profile.name">
                <div v-if="profile.industry" class="cell_text">
                    <a-icon slot="dot"  type="linkedin" style="font-size: 16px;" />
                    {{ profile.industry }}
                </div>
              </div>
            </section>
          </span>
          <span slot="usecases" slot-scope="usecases">
              <div v-for="tag in usecases"
                :key="tag"
                :color="tag === 'loser' ? 'volcano' : tag.length > 5 ? 'geekblue' : 'green'"
              >
                {{ tag }}
              </div>
            </span>
        </a-table>


        <!-- <ejs-grid ref="subscribers" :dataSource="subscribers"
            height='70vh'
            :allowPaging='true'
            :allowSelection='true'
            :pageSettings='subscriberTable.pageSettings'
        >
          <e-columns>
            <e-column field='id' :visible="false"></e-column>
            <e-column headerText='Name' :template='subscriberTable.nameTemplate' width='130'></e-column>
            <e-column headerText='Employer'  :template='subscriberTable.employerTemplate' width='130'></e-column>
            <e-column field='country' :template='subscriberTable.countryTemplate' headerText='Country' width='200'></e-column>
            <e-column :template='subscriberTable.resultTemplate' headerText='Usecases' width='200'></e-column>
            <e-column :template='subscriberTable.resultTemplate' headerText='Usecases' width='200'></e-column>
          </e-columns>
        </ejs-grid> -->
        </a-layout-content>
      </a-layout>
    </a-layout>

    <a-drawer
         :width="'65vw'"
         :visible="drawer.visible"
         :body-style="{ paddingBottom: '80px' }"
         @close="closeSubscriberDrawer"
       >

       <a-spin size="large" :spinning="drawer.spinning" style="width:100%">
         <Subscriber :subscriber="drawer.subscriber"  v-if="drawer.subscriber && drawer.subscriber.info"/>
       </a-spin>
       </a-drawer>
       <a-drawer
           :width="'500px'"
           :visible="filtersDrawer.show"
           placement="left"
           @close="filtersDrawer.show = false"
         >
         <div style="margin-top:20px;max-height:80vh;overflow-y:auto" v-if="namelist">
           <h4>{{ namelist.name }}</h4>
          <a-form layout="vertical">
            <a-form-item label="Result" >
            <a-select mode="multiple" v-model="search_params.usecase_groups" style="width: 100%">
              <a-select-option v-for="result in namelist.result_stats" :key="result.name" >
                {{ result.name }} - {{ result.count }}
              </a-select-option>
            </a-select>
            </a-form-item>

            <a-form-item label="Usecases" >
            <a-select mode="multiple" v-model="search_params.usecases" style="width: 100%">
              <a-select-option v-for="usecase in namelist.usecase_stats" :key="usecase.name" >
                {{ usecase.name }} - {{ usecase.count }}
              </a-select-option>
            </a-select>
            </a-form-item>

            <a-form-item label="Keywords">
            <a-select mode="multiple" v-model="search_params.keywords" style="width: 100%">
              <a-select-option v-for="keyword in namelist.keyword_stats" :key="keyword.keyword">
                  {{ keyword.keyword }} - {{ keyword.count }}
              </a-select-option>
            </a-select>
            </a-form-item>
            <a-form-item label="LinkedIn">
              <a-checkbox v-model="search_params.linkedin">
                Yes
              </a-checkbox>
            </a-form-item>
            <a-form-item>
              <a-button @click="clearFilters">
                   Clear Filters
               </a-button>
             </a-form-item>
            <a-form-item>
              <a-button @click="searchFilters">
                  Search
              </a-button>
            </a-form-item>

          </a-form>

         </div>
       </a-drawer>

  </div>
</template>
<style>
.address {
  font-size:12px;
}

.cell_text {
  font-size:.9em;
}
.employer {
  color:black;
  font-weight:500;
  font-size:13px;
}

.employment_title {
  font-size: 12px;
}

.email {
    font-size: 12px;
}

.e-card: {
  border: 1px solid #000;
}
.e-search:before{
    content:'\e993';
}
</style>
<script>
// import {Page } from "@syncfusion/ej2-vue-grids";
import linkedInTemplate from "../components/SubscriberTable.Columns.LinkedIn.vue";
import nameTemplate from "../components/SubscriberTable.Columns.Name.vue";
import employerTemplate from "../components/SubscriberTable.Columns.Employer.vue";
import countryTemplate from "../components/SubscriberTable.Columns.Country.vue";
import resultTemplate from "../components/SubscriberTable.Columns.Results.vue";


import Subscriber from '@/components/Subscriber.vue'

import namelist_subscriber_columns from '../configs/namelist_subscriber_columns'
import namelist_ria_columns from '../configs/namelist_ria_columns'

export default {
  name: 'Subscribers',
  components: {
    Subscriber
  },
  data() {
    return {
      namelist: null,
      subscribers: [],
      usecase_groups: {},
      usecaseFilter: {
        selected: [],
        options: []
      },
      pagination: {
        defaultPageSize: 100,
        showSizeChanger: true,
        pageSizeOptions: ['100', '200', '500'],
      },
      subscriberTable: {
        filter: '',
        columns: namelist_subscriber_columns.columns,
        pageSettings: {
          pageSize: 100,
          pageSizes: [100,1000,2000],
          pageCount: 4
        },
        nameTemplate: ()=>{
          return { template: nameTemplate }
        },
        nameTemplate: ()=>{
          return { template: nameTemplate }
        },
        employerTemplate: ()=>{
          return { template: employerTemplate }
        },
        linkedInTemplate: function () {
            return { template: linkedInTemplate}
        },
        countryTemplate: function () {
            return { template: countryTemplate}
        },
        resultTemplate: function () {
            return { template: resultTemplate}
        },
      },
      collapsed: false,
      search_params: {
        usecase_groups: [],
        country: '',
        keywords: [],
        usecases: [],
        limit:5000
      },
      filtersDrawer: {
        show: false
      },
      drawer: {
        spinning: true,
        subscriber: null,
        visible: false
      }
    };
  },
  created(){
    this.$eventHub.$on('subscriberSelected', (subscriber)=>{
      console.log(subscriber)
      this.subscriberRowSelected(subscriber)
    })
  },
  computed: {
    filteredSubs: function (){
  
      const self = this;
      return this.subscribers.filter((subscriber)=>{

        if(self.subscriberTable.filter && self.subscriberTable.filter.length > 2){
          if(!subscriber.name.toLowerCase().includes(this.subscriberTable.filter.toLowerCase())){
            return false
          }
        }

        if(self.usecaseFilter.selected.length != self.usecaseFilter.options.length ){
          const filteredArray = subscriber.usecases.filter(value => self.usecaseFilter.selected.includes(value)); 
          if(filteredArray.length == 0){
            return false
          }
        }

        return true
      })
    }
  },
  mounted: async function(){
    var self = this
    console.log(this.$route)
    this.namelist = {name:this.$route.params.namelist_name}

    var response = await this.$kysapi.get('api/namelists/' + this.$route.params.namelist_name , this.$auth)
    this.namelist = response.data.namelist
    if( this.namelist.process_type == 'ria'){
      console.log(this.namelist.process_type)
      this.subscriberTable.columns = namelist_ria_columns.columns
    }

    if(this.$route.query.usecase) {
      this.search_params.usecases.push(this.$route.query.usecase)
    }

    if(this.$route.query.result) {
      this.search_params.usecase_groups.push(this.$route.query.result)
    }

    if(this.$route.query.country) {
      this.search_params.country = this.$route.query.country
    }

    var stats_response = await this.$kysapi.get('api/namelists/' + this.namelist.id + '/stats', this.$auth)
    this.namelist = Object.assign(this.namelist , stats_response.data);

    console.log(this.namelist)

    const configuration_response = await this.$kysapi.get('api/usecases/configuration/default', this.$auth)
    console.log('configuration', configuration_response.data.configuration)

    const pieData = {}
    const group_map = {}
    const usecase_map = {}

    configuration_response.data.configuration.groups.forEach((group, i) => {
      pieData[group.name] = 0
      group.usecases = []
      self.usecase_groups[group.name] = group
    });

    configuration_response.data.configuration.usecases.forEach((uc, i) => {
      self.usecase_groups[uc.group].usecases.push(uc.name)
      usecase_map[uc.name] = uc.group
    });

    this.namelist.usecase_stats.forEach((uc, i) => {
      self.usecaseFilter.options.push(uc.name)
      self.usecaseFilter.selected.push(uc.name)
      pieData[usecase_map[uc.name]] += parseInt(uc.count)
    });

    self.namelist.result_stats = []
    configuration_response.data.configuration.groups.forEach((group, i) => {
      group.count = pieData[group.name]
      self.namelist.result_stats.push(group)
    });
    this.searchFilters()

    this.$pnSubscribe({
        channels: ['ch1']
    });
    this.$pnGetMessage('ch1', (m)=>console.log(m))
  },
  methods: {
    handleTableChange: function(pagination, filters, sorter) {
        console.log(sorter)
        // this.subscribers.sort( (a,b)=>{
        //   if (sorter && sorter.field === 'name') {
        //     if( a.name && b.name ){
        //       console.log(a.name, b.name)
        //       return a.name.localeCompare(b.name)
        //     }
        //   }
        //   return 0
        // })
        //
        // console.log(this.subscribers)
    },
    searchFilters: async function (){
      console.log(this.search_params)
      let self = this

      self.filtersDrawer.show = false

      let query = new URLSearchParams({
        limit: this.search_params.limit,
        order_by_subscriber_name: 'T',
        summary: true,
      })

      if (this.search_params.keywords.length > 0){
        query.append("keywords", this.search_params.keywords )
      }

      if (this.search_params.country.length > 0){
        query.append("country", this.search_params.country )
      }


      if(this.search_params.usecases.length > 0){
        query.append("usecases", this.search_params.usecases)
      } else if (this.search_params.usecase_groups.length > 0){

        let usecases = []
        this.search_params.usecase_groups.forEach((item, i) => {
          console.log(item)
          self.usecase_groups[item].usecases.forEach((uc, i) => {
            console.log(uc)
            usecases.push(uc)
          });
        });
        query.append("usecases", usecases )
      }

      if(this.search_params.linkedin){
        query.append("linkedin", "T")
      }

      var response = await this.$kysapi.get('api/subscribers/'  + this.namelist.name + "?" + query.toString(), this.$auth)
      const data = response.data
      self.subscribers = data.subscribers;
      console.log('finished')
      console.log(self.subscribers)
    },
    clearFilters: function(){
      this.search_params.keywords = [];
      this.search_params.usecases = [];
      this.search_params.linkedin = false;
    },
    closeSubscriberDrawer: function(){
      console.log('close drawer')
      this.drawer.visible = false;
    },
    requeueSubscriberUsecases: async function(){
      let data = { subscriber_id: this.drawer.subscriber.id }
      axios.post(base + '/api/subscriber/usecase', data)
        .then((res) => {
          console.log('queued usecase')
        })
        .catch((error) => {
          console.error('error for ' + data)
          console.error(error)
      })
    },
    subscriberRowSelected: async function(subscriber){
      this.drawer.spinning = true
      this.drawer.visible = true
      this.drawer.subscriber = subscriber
      var response = await this.$kysapi.get('api/subscriber/' + this.drawer.subscriber.id, this.$auth)
      let data =  response.data
      this.drawer.subscriber = data.subscriber
      this.drawer.spinning = false

      Object.assign(this.drawer.subscriber.linkedin_profile, this.drawer.subscriber.voyager_data);

      let params = {
        name: this.drawer.subscriber.name,
        type: 'individual',
        state: this.drawer.subscriber.state
      }
      var basic_search = await this.$kysapi.post('api/brokercheck', params, this.$auth)
      console.log('brokercheck', basic_search)

      console.log('selected subscriber', data.subscriber)
    }
  }
};
</script>
<style>
#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}
</style>
