<template>
  <div>
    <a-layout style="height:95vh" id="components-layout-demo-custom-trigger">

      <a-layout v-if="subscriber">
        <a-layout-header style="background: #fff;"></a-layout-header>
        <a-layout-content
          :style="{ background: '#fff', minHeight: '280px' }"
        >
        <a-row>
          <a-col :span="5"/>
          <a-col :span="15">
            <a-row style="padding-bottom:10px">
              <a-breadcrumb>
                <a-breadcrumb-item>
                  <router-link :to="{path: '/' }"><a-icon type="home" /></router-link>
                </a-breadcrumb-item>
                <a-breadcrumb-item href="">
                  <router-link  :to="{name: 'Namelist', params:{ id: subscriber.namelist_name}, query: {}}">{{ subscriber.namelist_name }}</router-link>
                </a-breadcrumb-item>
                <a-breadcrumb-item>
                  {{ nsid }}
                </a-breadcrumb-item>
              </a-breadcrumb>
            </a-row>
            <a-row>
              <Subscriber :subscriber="subscriber"/>
            </a-row>
          </a-col>
        </a-row>
      </a-layout-content>
      </a-layout>
    </a-layout>

  </div>
</template>
<style>
.address {
  font-size:12px;
}

.cell_text {
  font-size:.9em;
}
.employer {
  color:black;
  font-weight:500;
  font-size:13px;
}

.employment_title {
  font-size: 12px;
}

.email {
    font-size: 12px;
}

.e-card: {
  border: 1px solid #000;
}
.e-search:before{
    content:'\e993';
}
</style>
<script>
import Subscriber from '@/components/Subscriber.vue'

export default {
  name: 'SubscriberPage',
  components: {
    Subscriber
  },
  data() {
    return {
      nsid: null,
      subscriber_id: null,
      namelist: null,
      subscriber: null,
    };
  },
  mounted: async function(){
    var self = this
    console.log(this.$route)

    this.is_new_backend = false;

    if (this.$route.params.id) {
        this.subscriber_id = this.$route.params.id

    }
    //kysweb.prosparency.com/nsid/N659S1712741
    if (this.$route.params.nsid) {
      this.nsid = this.$route.params.nsid
      const ns = this.nsid.match(/\d+/g)
      if( ns.length === 2){
        this.subscriber_id = ns[1]
      }
    this.is_new_backend = this.nsid.startsWith("K");
  }
  if(this.subscriber_id){
      var api_endpoint = this.is_new_backend ? 'api/kys-subscriber/' : 'api/subscriber/'
      var response = await this.$kysapi.get(api_endpoint + this.subscriber_id , this.$auth)
      let data =  response.data
      console.log(response)
      this.subscriber = data.subscriber
      Object.assign(this.subscriber.linkedin_profile, this.subscriber.voyager_data);
    }
  }


  ,
};
</script>
<style>
#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}
</style>
